import { useSelector, useDispatch } from "react-redux";
import { StyleItemThumb } from "./StyleItemThumb";
import {
  selectSelectedStyleItem,
  setColorFilterText,
  selectColorFilterText,
  selectSortBy, 
  setSortBy,
  selectSortableByPrice
} from "../store/orderingSlice";
import { OffCanvasDrawerModal } from "./OffCanvasDrawerModal";
import { ColorDrawerStyleList } from "./ColorDrawerStyleList";
import classNames from "classnames";
import { SortSelect } from "./SortSelect";
import { FilterInput } from "./FilterInput";
import { InvalidSVG } from "./InvalidSVG";
import { ColorStyleSelectThumb } from "./ColorStyleSelectThumb";

function generateStyleItemStatusClassNames(base_class_name,si,is_selected)
{
  return classNames({
    [base_class_name] : true,
    [base_class_name + "--is-invalid"]: !si.valid,
    [base_class_name + "--is-valid"]: si.valid,
    [base_class_name + "--is-selected"]: is_selected,
    [base_class_name + "--is-backordered"]: si.backordered,
    [base_class_name + "--is-low-stock"]: si.low_stock
  });
}

export function ColorStyleSelect({ style, onClick, isOpen, dismissDrawer }) {
  const dispatch = useDispatch();
  const selected_style_item = useSelector(
    selectSelectedStyleItem(style.style_ref)
  );

  const sort_by = useSelector(selectSortBy);
  const filter_text = useSelector(selectColorFilterText);
  const sortable_by_price = useSelector(selectSortableByPrice);

  const state_classes = {
    "is-selected": selected_style_item.selected,
    "is-valid": selected_style_item.valid,
    "is-invalid": !selected_style_item.valid,
    "is-backordered": selected_style_item.backordered,
    "is-low-stock": selected_style_item.low_stock,
  };

  const label_class_name = classNames(
    {
      section_label: true,
      "style_ordering-label": true,
      "pb-2": true,
    },
    state_classes
  );

  const button_class_name = classNames(
    {
      btn: true,
      "btn-block": true,
      "style_ordering-box-modal_btn": true,
    },
    state_classes
  );

  const image_wrap_class_name = classNames(
    {
      "style_ordering-box-modal-image-wrap": true,
    },
    state_classes
  );

  const image_class_name = classNames(
    {
      "style_ordering-box-modal-image": true,
    },
    state_classes
  );

  const image_status_wrap_class_name = generateStyleItemStatusClassNames(
    "place-holder__item-image-status-wrap",
    selected_style_item,
    true
  );

  const text_wrap_class_name = classNames(
    {
      "style_ordering-box-modal-text-wrap": true,
    },
    state_classes
  );

  const name_class_name = classNames(
    {
      "style_ordering-box-modal-name": true,
    },
    state_classes
  );

  const model_class_name = classNames(
    {
      "style_ordering-box-modal-model": true,
    },
    state_classes
  );

  return (
    <>
      <label className={label_class_name}>{style.style_name}</label>
      <button
        type="button"
        className={button_class_name}
        onClick={() => {
          onClick();
        }}
      >
        <div className={image_wrap_class_name}>
          <div className={image_status_wrap_class_name}>
            <ColorStyleSelectThumb
              styleItem={selected_style_item}
              className={image_class_name}
            />
          </div>
          <InvalidSVG show={!selected_style_item.valid} />
          {selected_style_item.low_stock &&
            <span
              className="style_ordering-box-modal-image-icon is-low-stock"
              title="Low Stock"
            ></span>
          }          
        </div>
        <div className={text_wrap_class_name}>
          <div className={name_class_name}>
            {selected_style_item.style_item_name}
          </div>
          <div className={model_class_name}>
            {selected_style_item.style_item_model}
          </div>
        </div>
      </button>
      <OffCanvasDrawerModal
        dismissDrawer={dismissDrawer}
        isOpen={isOpen}
      >
        <div className="dynamic_cart">
          <div className="sticky-top">
            <div className="dynamic_cart-header">
              <h3>Colors</h3>
              <button
                type="button"
                className="icon icon-x dynamic_cart-header-close"
                title="Close Cart"
                onClick={dismissDrawer}
              >
                Close
              </button>
            </div>
            <div className="color-drawer__search-bar row">
              <div className="color-drawer__sort-column col-6 col-xs-8">
                <FilterInput 
                  filterText={filter_text}
                  onChange={e => { dispatch(setColorFilterText(e.target.value)) }}
                />
              </div>
              <div className="color-drawer__sort-column col-6 col-xs-4">
                <SortSelect
                  sortBy={sort_by}
                  onChange={e => { dispatch(setSortBy(e.target.value)) }}
                  sortableByPrice={sortable_by_price}
                />
              </div>
            </div>
          </div>
          <ColorDrawerStyleList style={style} />
        </div>
      </OffCanvasDrawerModal>
    </>
  );
}
