export function isProductOrderable(product)
{
    //No Web Ordering
    if(product.web_ordering === false)
    {
      return false;
    }
  
    //Out of Stock
    if(product.in_stock === false)
    {
      if(product.no_backorders === true)
      {
        return false;
      }
    }
    return true;
}
export function availableTextHasDays(available_text) {
  if (!available_text) {
    return false;
  }
  return available_text.toLowerCase().includes('days');
}
export function availableTextHasPlus(available_text) {
  if (!available_text) {
    return false;
  }
  return available_text.includes('+');
}
export function getStockMessageData(product_matches) {

  // More than one match, no message
  if (product_matches.length > 1) {
    return null;
  }

  // No matches, unavailable
  if (product_matches.length === 0) {
    return {
      messageType: "Unavailable",
      messageStatus: "Invalid"
    };
  }

  let product = product_matches[0];

  //Not Purchaseable (in category ALLPROMO)
  if (product.not_purchaseable === true) {
    return {
      messageType: "NotPurchaseable",
      messageStatus: "LowStock"
    };
  }

  if (product.in_store_only === true) {
    return {
      messageType: "InStoreOnly",
      messageStatus: "LowStock"
    };
  }

  //No Web Ordering
  if (product.web_ordering === false) {
    if (product.preorder === true) {
      return {
        messageType: "PreRelease",
        availableText: product.available_text
      };
    }

    if (product.in_stock === true) {
      return {
        messageType: "NoWebOrdering",
        messageStatus: "Invalid"
      };
    }

    return {
      messageType: "OutOfStock",
      messageStatus: "Invalid"
    };
  }

  //Out of Stock
  if (product.in_stock === false) {
    if (product.no_backorders === true) {
      return {
        messageType: "NoBackorder",
        messageStatus: "Backorder",
        availableText: product.available_text,
        notifyStock: product.stock_notify,
        notifyCode: product.code
      };
    }

    if (availableTextHasDays(product.available_text)) {
      return {
        messageType: "Backorder",
        messageStatus: "Backorder",
        availableText: product.available_text
      };
    }

    if (product.preorder === true) {
      return {
        messageType: "PreOrder",
        availableText: product.available_text,
        notifyStock: product.stock_notify,
        notifyCode: product.code
      };
    }
    return {
      messageType: "Backorder",
      messageStatus: "Backorder",
      availableText: product.available_text,
      notifyStock: product.stock_notify,
      notifyCode: product.code
    };
  }

  //Not Drop Shipped and has definite quantity (XX in stock)
  if (product.web_stock === true && product.drop_shipped === false && !availableTextHasPlus(product.available_text)) {
    return {
      messageType: "Available",
      messageStatus: "LowStock",
      availableText: product.available_text
    };
  }
  return null;
}

