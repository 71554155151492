import { ResponsiveProductImage } from "./ResponsiveProductImage";
import { useSelector } from "react-redux";
import {
  selectOwnerCode,
  selectResizeURL
} from "../store/orderingSlice";

export function BulkOrderingThumb({ styleItem, className = "thumbnail"  }) {
  const owner_code = useSelector(selectOwnerCode);
  const resize_url = useSelector(selectResizeURL);
  const size_breakpoints = {
    0: 190,
    480: 238,
    576: 330,
    768: 272,
    992: 342,
    1200: 266,
    1148: 262
  };
  const srcset_widths = [
    190,
    238, 
    330, 
    272, 
    342, 
    266, 
    262, 
    380,
    476,
    660,
    544,
    684,
    532,
    524
  ];
  const default_width = 190;

  return (
    <ResponsiveProductImage
      resizeURL={resize_url}
      path={owner_code + "-" + styleItem.style_item_code + "-1.jpg"}
      srcsetWidths={srcset_widths}
      sizeBreakpoints={size_breakpoints}
      defaultWidth={default_width}
      alt={styleItem.style_item_name}
      className={className} />
  );
}
